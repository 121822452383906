<template>
	<div class="home-page"></div>
</template>
<script>
    import {
		mapMutations, mapState
		} from 'vuex';
    export default {		
		data() {
			return {
				enid: this.$route.query.enid
            }
        },
        computed:{
			...mapState({
				'lang':state=>state.app.currentLang,
				'webcontents':state=>state.app.webcontents,
				'current':state=>state.app.current,
				'en_id':state=>state.app.en_id
			}),
        },
        created(){
            if (this.enid && this.enid !== ' '){                
				console.log(this.enid)
				this.setEnId(this.enid)
			}
			if(!this.webcontents || this.webcontents.length == 0){
				this.$axios.get("https://jesway.cn/api/website/all_websites?en_id="+this.en_id).then(res=>{
					this.setWebInfo(res.data)
					// console.log(JSON.stringify(this.webcontents))					
					// this.thispage = res.data.list.filter(item=>item.type == 'Home')[0]
					// this.getData()
                    this.$router.push({ path: '/'})
				})
			}else{
                this.$router.push({ path: '/'})
            }
			
		},
        methods: {
			...mapMutations({
				setWebInfo(commit, webcontent) {
					commit('app/SET_WEB', webcontent)
				},
				setEnId(commit, en_id) {
					commit('app/SET_EN_ID', en_id)
				}
			})
        }

    }

</script>